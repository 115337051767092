import imgLogo from '../assets/img/rocks engineering logo.png'
import imgSlider from '../assets/img/chat_pat2.png'
import { Link } from 'react-router-dom'
export default function Navbar() {
    return (
        <>
        <div class="side_menu_style4">
        <div class="content">
            <div class="main_links">
                <ul>
                    <li><Link onClick={()=>{
            document.querySelector('.side_menu_style4').classList.remove("show")
        }} to="/" class="main_link"> home </Link> </li>
                    <li><Link onClick={()=>{
            document.querySelector('.side_menu_style4').classList.remove("show")
        }} to="/blog" class="main_link"> Latest Posts
 </Link></li>
                    <li><Link onClick={()=>{
            document.querySelector('.side_menu_style4').classList.remove("show")
        }} to="/services" class="main_link"> Services </Link> </li>
                </ul>
            </div>
        </div>
        <img src={imgSlider} alt="" class="side_shape"/>
        <img src={imgSlider} alt="" class="side_shape2"/>
        <span class="clss" onClick={()=>{
            document.querySelector('.side_menu_style4').classList.remove("show")
        }}> <i class="fas fa-times"></i> </span>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
            <div class="container-fluid content">
                <Link class="navbar-brand" to="/">
                    <img src={imgLogo} alt="" class="logo" />
                </Link>
            
                <div class="nav-side me-0 mx-auto">
                   
                
                    <a href="#" class="icon ms-3 side_menu_btn fsz-21" onClick={()=>{
                        document.querySelector('.side_menu_style4').classList.add("show")
                    }}>
                        <span> <i class="far fa-grip-lines"></i> </span>
                    </a>
                </div>
            </div>
        </nav>
        </>
    )
}