import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import apiUrl from "../api/config"

export default function BlogDetails() {

    const {blogId} = useParams()
    const [data , setData] = useState()
  

    useEffect(()=>{
        async function fetchData () {
            let res = await fetch(`${apiUrl}/blogs/${blogId}`)
            let data = await res.json()
            setData(data.data)
        }
        fetchData ()
    },[blogId])


    return(
        <>
        <section class="tc-main-img-style1 header" >
                <div class="main-img">
                    <img id="coverPhotoDetails" src={data?.image} alt="" class="img-cover" data-speed="0.25" data-lag="0" />
                    <p>{data?.description}</p>
                </div>
                <div class="content">
                    <div class="container">
                        <div class="info">
                            <div class="date fsz-12 text-uppercase mb-30">
                                <a href="#"> {data?.tag_of_cover} </a>
                                {/* <span class="circle icon-3 bg-white rounded-circle mx-3"></span> */}
                                <a href="#"> {data?.created_at} </a>
                            </div>
                            <h2 class="title"> <a href="#" class="hover-orange1"> {data?.title} </a> </h2>
                        </div>
                    </div>
                </div>
            </section>
        <section class="tc-post-content-style1">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="info">
                                <div class="breadcromb wow fadeInUp slow" data-wow-delay="0.2s">
                                    <Link to="/"> Home </Link>
                                    <span class="icon">/</span>
                                    <Link to="/blog"> blog </Link>
                                    <span class="icon">/</span>
                                    <a href="#"> {data?.title} </a>
                                </div>
                               
                               
                            </div>
                        </div>
                    </div>
                    {
                        data?.details.map((blog)=>{
                            return(
                                   <div class="imgs mb-40">
                         <h6 class="fsz-24 fw-600 mb-60 wow fadeInUp slow" data-wow-delay="0.2s"> {blog?.title_description}</h6>
                                <div class="text fsz-14 color-666 mb-70 wow fadeInUp slow" data-wow-delay="0.2s">
                                    {blog?.body_description}
                                </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="item text-center mb-50 wow zoomIn slow" data-wow-delay="0.2s">
                                    <div class="img th-450 radius-6 overflow-hidden">
                                        <img src={blog?.sales_image} alt="" class="img-cover"/>
                                    </div>
                                    
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                            )
                        })
                    }
                  
                </div>
            </section>
        </>
    )
}