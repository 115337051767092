import { useEffect, useState } from 'react'
import img1 from '../assets/img/blog/blog1.jpg'
import apiUrl from '../api/config'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { Link } from 'react-router-dom'

export default function BlogSlide () {

    const [blog , setBlog] = useState([])

    useEffect(()=>{
        async function fetchData () {
            let res = await fetch (`${apiUrl}/blogs`)
            let data = await res.json()
            
            setBlog(data.data)
        }
       
        fetchData()
    },[])





    return (
        <section class="tc-blog-style1" data-section="7">
        <div class="container-fluid position-relative">
            <div class="mb-80 pt-100">
                <div class="row title">
                    <div class="col-lg-9 text-uppercase wow">
                        <h2 class="fsz-45"> Latest Posts </h2>
                    </div>
                    <div class="col-lg-3 text-lg-end mt-4 mt-lg-0 text-uppercase wow">
                        <Link to="/blog" class="butn border rounded-pill color-orange1 border-orange1 hover-bg-orange1">
                            <span> All Articles <i class="small ms-1 fas fa-arrow-right"></i> </span>
                        </Link>
                    </div>
                </div>
            </div>
            <Swiper 
                slidesPerView={3}
                breakpoints={{
                    140: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
            navigation={{
                nextEl: '#blog-next',
                prevEl: '#blog-prev',
            }}  modules={[Navigation]} class="blog-slider position-relative overflow-hidden px-4">
               
                    {
                        blog?.map((blog, index)=>{
                     
                            return(
                                <SwiperSlide  class="swiper-slide" key={blog.id}>
                                <div class="blog-card">
                                    <div class="img">
                                        <img src={blog?.image} alt="" class="img-cover"/>
                                    </div>
                                    <div class="info">
                                        {
                                            blog.created_at &&
                                                <div class="date">
                                                    <div class="num fsz-45 mb-2"> {blog.created_at.splice} </div>
                                                    <small class="fsz-12 text-uppercase color-666"> {blog.created_at} </small>
                                                </div>
                                        }
                                        <div class="cont">
                                            <Link to={`/blog-details/${blog.id}`} class="title d-block fsz-20 hover-orange1 mb-15 fw-600"> {blog.title} </Link>
                                            <small class="fsz-12 "> {blog.description} </small>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            )
                        })
                    }
               
                  
     
            </Swiper>
            <div class="swiper-button-next" id='blog-next'></div>
            <div class="swiper-button-prev" id='blog-prev'></div>
        </div>
    </section>
    )
}