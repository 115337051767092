import videoHome from '../assets/websiteOpening.mp4'
import BlogSlide from '../components/blogSlide'
import ServiceSlider from '../components/serviceSlide'

export default function HomePage () {
    return (
        <>
            <header class="tc-header-style1" style={{translate: "none", rotate: "none" , scale: "none", transform: "translate(0px, 0px) !important"}}>
                    <video src={videoHome}  muted  autoPlay={true} loop  class="img-fluid w-100">
                        <source src={videoHome}/>
                    </video>
            </header>
            <ServiceSlider/>
            <BlogSlide/>
        </>
    )
}